.text-content {
  p {
    @apply mt-4 text-base;
  }

  h2 {
    @apply mt-8;
  }

  ol {
    @apply pl-8 pt-2 text-base;
    list-style: lower-latin;
  }

  section {
    @apply mt-12;
  }
}
