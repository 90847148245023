.aim {
  &__left_bottom {
    @apply absolute bottom-[-12px] left-[-12px] md:bottom-[-24px] md:left-[-24px] border-b-2 border-l-2 border-primary rounded-bl-[12px] h-[40px] w-[40px] md:h-[80px] md:w-[80px];
  }

  &__right_top {
    @apply absolute top-[-12px] right-[-12px] md:top-[-24px] md:right-[-24px] border-t-2 border-r-2 border-primary rounded-tr-[12px] h-[40px] w-[40px] md:h-[80px] md:w-[80px];
  }

  &__right_bottom {
    @apply absolute right-[-12px] bottom-[-12px] md:right-[-24px] md:bottom-[-24px] border-b-2 border-r-2 border-primary rounded-br-[12px] h-[40px] w-[40px] md:h-[80px] md:w-[80px];
  }

  &__left_top {
    @apply absolute top-[-12px] left-[-12px] md:top-[-24px] md:left-[-24px] border-t-2 border-l-2 border-primary rounded-tl-[12px] h-[40px] w-[40px] md:h-[80px] md:w-[80px];
  }
}
