#walletconnect-wrapper {
  .walletconnect-modal__base {
    margin-top: 20px;
    max-height: 80vh;
  }
  .walletconnect-search__input {
    display: none;
  }
  .walletconnect-qrcode__image {
    max-height: calc(60vh - 150px);
  }
  .walletconnect-connect__buttons__wrapper__android {
    margin: auto 0;
    padding: 20px 0;
  }
}
