@import "custom-scroll";
@import url("https://fonts.googleapis.com/css2?family=Saira:wght@600&display=swap");

@font-face {
  font-family: "Google Sans";
  font-display: swap;
  src: url("/fonts/Google-Sans.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("/fonts/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Saira";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url("/fonts/Saira-SemiBold") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@import "text.module.scss";
@import "wallet-connect.module.scss";
@import "tooltip.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --screen-height: 100vh;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, Fira Sans, -apple-system, sans-serif;
}

body.no-scroll {
  overflow: hidden;
  height: calc(var(--screen-height) - 1px);
  @apply lg:overflow-visible lg:h-auto w-auto;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

// *:not(input, textarea) {
//   -webkit-touch-callout: none;
//   -webkit-user-select: none; // locks fields on Safari
//   -khtml-user-select: none; // locks fields on Safari
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
}

@layer utilities {
  .text-saira {
    font-weight: 600;
    font-family: "Saira", sans-serif;
  }

  .text-shadow-md {
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
  }

  .text-shadow-light-sm {
    text-shadow: 0 0.25rem 0 rgba(0, 0, 0, 0.15);
  }

  .text-shadow-light-xs {
    text-shadow: 0 0.125rem 0 rgba(0, 0, 0, 0.15);
  }

  .text-shadow-lg {
    text-shadow: 0 4px 54px rgba(0, 0, 0, 0.8);
  }

  .text-shadow-none {
    text-shadow: none;
  }

  .text-shadow-without-blur {
    text-shadow: 0 0.1875rem 0 rgba(0, 0, 0, 0.25);
  }

  .text-shadow-without-blur-sm {
    text-shadow: 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  }

  .text-shadow-without-blur-lg {
    text-shadow: 0 0.375rem 0 rgba(0, 0, 0, 0.25);
  }

  .text-shadow-sm {
    text-shadow: 0 4px 0 rgba(43, 25, 71, 0.35);
  }

  // safe-area-padding
  .safe-px {
    padding-left: max(1.5rem, env(safe-area-inset-left));
    padding-right: max(1.5rem, env(safe-area-inset-right));
  }

  .safe-pl {
    padding-left: max(1.5rem, env(safe-area-inset-left));
  }

  .safe-pl-0 {
    padding-left: env(safe-area-inset-left);
  }

  .safe-pr {
    padding-right: max(1.5rem, env(safe-area-inset-right));
  }

  .safe-mx {
    margin-left: max(1.5rem, env(safe-area-inset-left));
    margin-right: max(1.5rem, env(safe-area-inset-right));
  }
}

h1 {
  @apply text-h1-mobile md:text-h1 font-extrabold;
}

h2 {
  @apply text-h2-mobile md:text-h2 font-bold;
}

h3 {
  @apply text-h3-mobile md:text-h3 font-bold;
}

h4 {
  @apply text-h4 font-bold;
}

h5 {
  @apply text-h5;
}

p {
  @apply text-table font-medium;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
