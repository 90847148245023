.gradient-button {
  &__primary-deep-blue {
    background: linear-gradient(93.3deg, #bb08ff 0%, #5218e5 100%);
    box-shadow: inset 0px -1px 1px #4705CC, inset 0px 1px 1px rgba(255, 255, 255, 0.25);

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)),
        linear-gradient(93.3deg, #bb08ff 0%, #5218e5 100%);
    }
  }

  &__primary-heatmap {
    background: linear-gradient(93.3deg, #ffb80c 0%, #ff109d 100%);
    box-shadow: 0px 4px 16px rgba(255, 102, 81, 0.5);
    backdrop-filter: blur(16px);

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)),
        linear-gradient(93.3deg, #ffb80c 0%, #ff109d 100%);
    }
  }

  &__primary-purple {
    background: linear-gradient(93.3deg, #ff1996 0%, #ad00ff 100%);
    box-shadow: inset 0px -1px 1px rgba(47, 24, 191, 0.5), inset 0px 1px 1px rgba(255, 255, 255, 0.5), 0px 4px 16px rgba(222, 18, 200, 0.25);
    backdrop-filter: blur(16px);

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)),
        linear-gradient(93.3deg, #ff1996 0%, #ad00ff 100%);
    }
  }

  &__disabled {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)),
    linear-gradient(93.3deg, #9F97EA 0%, #5C53A6 100%);

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)),
      linear-gradient(93.3deg, #9F97EA 0%, #5C53A6 100%);
    }
  }
}
