.button {
  @apply inline-flex items-center justify-center text-center shrink-0;
  @apply font-bold text-center;
  @apply focus:outline-none;
  @apply disabled:opacity-50 disabled:select-none disabled:cursor-not-allowed;
  @apply relative overflow-hidden;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);

  &:active:not(:disabled) {
    .overlay {
      @apply left-[-1.5rem] right-[-1.5rem];
    }
  }

  &_no-text-shadow {
    text-shadow: none;
  }
}

.volume {
  transition: all 50ms linear;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.61), 0 2px 0 rgba(0, 0, 0, 0.2);
  @apply absolute left-0 top-[1px] right-0 bottom-[2px] rounded-[0.25rem];
}

.overlay {
  clip-path: polygon(
    25px 100%,
    1px 54%,
    0% 52%,
    0% 50%,
    0% 48%,
    1px 46%,
    25px 0%,
    calc(100% - 25px) 0%,
    calc(100% - 1px) 46%,
    100% 48%,
    100% 50%,
    100% 52%,
    calc(100% - 1px) 54%,
    calc(100% - 25px) 100%
  );
  transition: all 150ms ease-in-out;
  @apply absolute left-[1rem] top-0 right-[1rem] bottom-0;
}

.primary {
  @apply text-white hover:text-white uppercase;

  &__gradient {
    @apply bg-gradient-to-l from-primary to-primary/60;
  }

  .volume {
    @apply bg-gradient-to-t from-primary to-primary/95;
  }

  .overlay {
    @apply bg-[#9c0d5a4d];
  }
}

.secondary {
  @apply text-white disabled:hover:bg-primary hover:text-white;

  &__gradient {
    @apply bg-gradient-to-b from-[#554EA7] to-[#384297];
  }

  .volume {
    @apply bg-gradient-to-b from-[#554EA7] to-[#384297];
  }

  .overlay {
    @apply bg-[#2527794d];
  }
}

.icon {
  @apply disabled:hover:bg-white bg-white border border-dark;
}

.sm {
  @apply px-7 py-1/2;

  &.no_overlay {
    @apply px-4;
  }
}

.md {
  @apply px-8 py-2.5;

  &.no_overlay {
    @apply px-5;
  }
}

.lg {
  @apply px-9 py-4;

  &.no_overlay {
    @apply px-6;
  }
}

.action {
  @apply rounded-xl text-table;
  background: linear-gradient(90deg, #4f4acc 22.4%, rgba(79, 74, 204, 0.6) 100%);
  overflow: visible;
}
