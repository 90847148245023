.header {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);

  &__opened {
    box-shadow: none;
    overflow-y: hidden;
  }

  &_submenu,
  &_mobile-menu {
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.25);
  }

  &_submenu-title {
    text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
  }

  &_active-item {
    &:after {
      content: "";
      position: absolute;
      width: 80px;
      height: 80px;
      left: calc(50% - 80px / 2 + 0.5px);
      top: 60px;

      background: #993cff;
      filter: blur(30px);
    }
  }
}
